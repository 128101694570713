import axios, { AxiosError } from 'axios';

import { ALREADY_VERIFIED } from '@/lib/constants/email';
import {
  AddStripeBankAccountRequest,
  AddStripeBankAccountResponse,
} from '@/types/api/fintech';
import {
  Customer,
  ResetPasswordRequest,
  SendForgotPasswordEmailRequest,
  UpdateEmailRequest,
  UserSignUpRequest,
} from '@/types/api/users';
import { ApiError, PermissionsError, StripeError } from '@/types/errors';
import { HttpError, SuccessConfirmation } from '@/types/utilityTypes';

import { api } from './axios';

export const addStripeBankAccount = async (
  req: AddStripeBankAccountRequest
) => {
  try {
    const response = await api.post<AddStripeBankAccountResponse>(
      '/api/add-stripe-bank-account',
      req
    );

    return response.data;
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.code === AxiosError.ERR_BAD_REQUEST
    ) {
      throw new StripeError({
        name: 'EXISTING_BANK_ERROR',
        message: `Failed to connect bank account with error: 
        ${error.response?.data.message}`,
      });
    }
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to create Stripe customer with error: ${axiosError.response?.data.message}`
    );
  }
};

export const customer = async () => {
  try {
    const response = await api.get<Customer>('/api/user/customer');

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to fetch customer with error: ${axiosError.response?.data.message}`
    );
  }
};

export const resetPassword = async (req: ResetPasswordRequest) => {
  try {
    const response = await axios.post<SuccessConfirmation | HttpError>(
      '/api/auth/reset-password',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(axiosError.response?.data.message);
  }
};

export const sendForgotPasswordEmail = async (
  req: SendForgotPasswordEmailRequest
) => {
  try {
    const response = await axios.post<SuccessConfirmation | HttpError>(
      '/api/emails/forgot-password',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to send password reset email with error: ${axiosError.response?.data.message}`
    );
  }
};

export const signUp = async (req: UserSignUpRequest) => {
  try {
    const response = await api.post<null>('/api/user/signup', req);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      throw new PermissionsError({
        name: 'INSUFFICIENT_PERMISSIONS',
        message: 'User does not have sufficient permissions to call this api.',
      });
    }
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(axiosError.response?.data.message);
  }
};

export const updateEmail = async (req: UpdateEmailRequest) => {
  try {
    const response = await api.post<null>('/api/user/update-email', req);

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to update user: ${axiosError.response?.data.message}`
    );
  }
};

/**
 * Verifies the customer's email.
 * @param uuid The verification code to use as a key.
 * @returns true if the user was already verified, false otherwise.
 * Throws on error.
 */
export const verifyCustomerEmail = async (uuid: string) => {
  try {
    const response = await api.post<null | HttpError>(
      '/api/user/verify-customer-email',
      {
        token: uuid,
      }
    );

    return response.data?.message === ALREADY_VERIFIED;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new ApiError(
      `Failed to update user: ${axiosError.response?.data.message}`,
      axiosError.response?.status ?? 500
    );
  }
};
