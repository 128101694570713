import * as amplitude from '@amplitude/analytics-browser';
import { FileWithPath } from '@mantine/dropzone';
import { useContext } from 'react';

import { AnalyticsEvent, PAGES } from '@/lib/constants/analyticsEvents';
import { Document } from '@/types/api/documents';

import { DisplaySize, SizeContext } from '../contexts/SizeContext';
import { CottageButton } from '../default';
import DocLink from '../DocLink';

type OnDeleteFile = (fileName: string) => void;

type SingleFileDocLinkProps = {
  existingFiles: Document[];
  filesToUpload: FileWithPath[];
  onDeleteExisting: OnDeleteFile;
  onDeleteNewFile: OnDeleteFile;
  page: PAGES;
};

export const SingleFileDocLink = ({
  existingFiles,
  filesToUpload,
  onDeleteExisting,
  onDeleteNewFile,
  page,
}: SingleFileDocLinkProps) => {
  const sizeContext = useContext(SizeContext);

  let docName = '';
  let docUuid = '';
  let onDelete: OnDeleteFile;
  if (existingFiles.length > 0) {
    docName = existingFiles[0].name;
    docUuid = existingFiles[0].uuid;
    onDelete = onDeleteExisting;
  } else if (filesToUpload.length > 0) {
    docName = filesToUpload[0].name;
    onDelete = onDeleteNewFile;
  }

  if (!docName) {
    return <></>;
  }

  const importNewButton = (
    <CottageButton
      variant="default"
      onClick={() => {
        amplitude.track({
          event_type: AnalyticsEvent.DOCUMENT_IMPORT_NEW_FILE_CLICKED,
          event_properties: {
            currentPage: page,
            documentName: docName,
          },
        });
        onDelete(docName);
      }}
    >
      Import new file
    </CottageButton>
  );

  return sizeContext.size === DisplaySize.COMPACT ? (
    <>
      <DocLink key={docName} title={docName} documentUuid={docUuid} />
      {importNewButton}
    </>
  ) : (
    <DocLink key={docName} title={docName} actionButton={importNewButton} />
  );
};
