import * as amplitude from '@amplitude/analytics-browser';
import { Divider, Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CompanyMedal, MediaType } from '@prisma/client';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';

import { AnalyticsEvent } from '@/lib/constants/analyticsEvents';
import { Company } from '@/types/api/company';
import { Media } from '@/types/api/media';
import { ProposalCompany } from '@/types/api/proposals';

import { CompanyAttributes } from '../proposals/CompanyAttributes';
import { HighlightBadge } from '../proposals/HighlightBadge';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { CottageMediaCarousel } from '../shared/default/CottageMediaCarousel';
import { MediaModal } from '../shared/default/CottageVideo';
import { ModalType, TwoColumnModal } from '../shared/modals/TwoColumnModal';
import { CompanyTestimonials } from './CompanyTestimonials';
import { ProfileHeader } from './ProfileHeader';
import { SuperProBadge, SuperProBadgeType } from './SuperProBadge';

type CompanyProfileQuickViewProps = {
  company: ProposalCompany | Company;
  onClose: () => void;
  opened: boolean;
  projectMunicipality?: string;
};

// NOTE: If you are editing this file, double check whether the edits should
// also be replicated in src/components/company/CompanyProfileRedactedQuickView.tsx.
export const CompanyProfileQuickView = ({
  company,
  onClose,
  opened,
  projectMunicipality,
}: CompanyProfileQuickViewProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  // Analytics
  useEffect(() => {
    if (company) {
      amplitude.track({
        event_type: AnalyticsEvent.COMPANY_QUICK_VIEW_MODAL_OPENED,
        event_properties: {
          companyId: company.id,
        },
      });
    }
  }, [company]);

  // Local state is used to manage transition from the quick view modal itself
  // to the media modal.
  const [
    isQuickViewModalOpened,
    { open: openQuickViewModal, close: closeQuickViewModal },
  ] = useDisclosure(false);
  const [isMediaModalOpened, { open: openMediaModal, close: closeMediaModal }] =
    useDisclosure(false);

  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(
    undefined
  );

  // The overall opened state of this component is managed by its parent
  // component via this effect. This approach allows the parent of this
  // component to not have to micromanage the opened state of the modals
  // in this component. Instead, the parent component only needs to control
  // whether the modal group is opened/closed, which leaves the logic of opening
  // and closing specific modals to this component.
  useEffect(() => {
    if (opened) {
      openQuickViewModal();
    } else {
      closeQuickViewModal();
      closeMediaModal();
    }
  }, [opened, openQuickViewModal, closeQuickViewModal, closeMediaModal]);

  if (!company && opened) {
    Sentry.captureMessage(`CompanyProfileQuickView has opened but company is 
      missing`);

    return <></>;
  }

  if (!company) {
    return <></>;
  }

  const companyTestimonials = company.company_testimonial;
  const companyBadges = company.company_derived_data_cache?.badges;

  const handleMediaClicked = (media: Media) => {
    setSelectedMedia(media);
    closeQuickViewModal();
    openMediaModal();
  };
  const handleVideoModalClosed = () => {
    setSelectedMedia(undefined);
    openQuickViewModal();
    closeMediaModal();
  };

  return (
    <>
      <TwoColumnModal
        modalType={ModalType.WIDE}
        opened={isQuickViewModalOpened}
        onClose={onClose}
        allowReopen
        withClose
      >
        {!isMobile && (
          <TwoColumnModal.Media>
            <CottageMediaCarousel
              images={company.media ?? []}
              size={424}
              onMediaClicked={handleMediaClicked}
            />
          </TwoColumnModal.Media>
        )}

        <TwoColumnModal.Text>
          <Stack spacing="sm">
            {company.medal === CompanyMedal.SUPERPRO && (
              <SuperProBadge displayType={SuperProBadgeType.SPREAD} />
            )}
            <ProfileHeader
              company={company}
              displayWebsite
              hideMedal
              projectMunicipality={projectMunicipality}
            />
          </Stack>
          {/* Attributes block */}
          <CompanyAttributes
            company={company}
            projectMunicipality={projectMunicipality}
            includeDivider
          />
          <Text c="blue.9">{company.about_info}</Text>
          {/* Clients say block */}
          {(companyBadges && companyBadges.length > 0) ||
          (companyTestimonials && companyTestimonials.length > 0) ? (
            <>
              <Divider />
              <Title order={5}>Clients say</Title>
              {/* Badges block */}
              {companyBadges && companyBadges.length > 0 && (
                <Group spacing="xxs">
                  {companyBadges.map((tag) => (
                    <HighlightBadge key={tag} highlightTag={tag} />
                  ))}
                </Group>
              )}
              {/* Testimonial block */}
              {companyTestimonials && companyTestimonials.length > 0 && (
                <CompanyTestimonials
                  companyTestimonials={companyTestimonials}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </TwoColumnModal.Text>
      </TwoColumnModal>
      <MediaModal
        media={selectedMedia}
        opened={isMediaModalOpened}
        onClose={handleVideoModalClosed}
        size={selectedMedia?.media_type === MediaType.VIDEO ? 'xl' : '100%'}
      />
    </>
  );
};
