export const openExternalUrl = (url: string, newTab = true) => {
  // We need to wrap the window.open call in a setTimeout because iPhones
  // require window.open to be executed on the main thread, which setTimeout
  // does. This makes it possible to open urls in the context of an async
  // function.
  setTimeout(() => {
    if (newTab) {
      window.open(url, '_blank') || window.location.replace(url);

      return;
    }
    window.location.href = url;
  });
};
