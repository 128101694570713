import {
  createStyles,
  Group,
  Paper,
  PaperProps,
  Stack,
  Text,
} from '@mantine/core';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { useContainerStyles } from '@/lib/styleUtils';

const useStyles = createStyles((theme) => ({
  action: {
    color: theme.colors.blue[7],
    fontWeight: 700,
  },
}));

type BaseCottagePaperProps = PropsWithChildren<
  {
    avatarElement?: React.JSX.Element | null;
    badge?: React.JSX.Element;
    rightIcon?: React.JSX.Element;
    subtitle?: string | null;
    title?: string | null | undefined;
  } & PaperProps
>;

type CottagePaperPropsWithLink = BaseCottagePaperProps & {
  action: string;
  actionLink: string;
  actionElement?: never;
};

type CottagePaperPropsWithCallback = BaseCottagePaperProps & {
  action?: never;
  actionElement?: React.JSX.Element;
  actionLink?: never;
};

type CottagePaperPropsWithoutAction = BaseCottagePaperProps & {
  action?: never;
  actionElement?: never;
  actionLink?: never;
};

export type CottagePaperProps =
  | CottagePaperPropsWithLink
  | CottagePaperPropsWithCallback
  | CottagePaperPropsWithoutAction;

/**
 * Creates a reusable Mantine Paper component with 24px padding and 24px padding between
 * its children elements.
 * @returns CottagePaper
 */
export const CottagePaper = ({
  children,
  action,
  actionElement,
  actionLink,
  avatarElement,
  title,
  subtitle,
  badge,
  rightIcon,
  ...props
}: CottagePaperProps) => {
  const { classes } = useStyles();
  const { classes: containerClasses } = useContainerStyles();

  const hasHeader =
    badge || avatarElement || title || actionLink || actionElement || rightIcon;

  return (
    <Paper className={containerClasses.padding} withBorder {...props}>
      <Stack className={containerClasses.gap}>
        {hasHeader && (
          <Stack spacing="xxxs">
            <Group position="apart" align="start" noWrap={!!actionElement}>
              <Stack spacing="xxxs">
                {badge}
                {avatarElement}
                {title && <Text variant="headerXl">{title}</Text>}
              </Stack>
              {actionLink && (
                <Link
                  className={classes.action}
                  href={actionLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {action}
                </Link>
              )}
              {actionElement}
              {rightIcon}
            </Group>

            {subtitle && <Text variant="subtitle">{subtitle}</Text>}
          </Stack>
        )}
        {children}
      </Stack>
    </Paper>
  );
};
