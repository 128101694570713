import { MediaType, Prisma } from '@prisma/client';

import { XOR } from '../utilityTypes';

/**
 * ****************************************************************************
 * Back-end Types
 * ****************************************************************************
 */

export const pgGalleryInclude = Prisma.validator<Prisma.galleryDefaultArgs>()({
  include: {
    media: {
      where: {
        is_deleted: false,
      },
      orderBy: {
        gallery_position: 'asc',
      },
    },
  },
});

export type PgGallery = Prisma.galleryGetPayload<typeof pgGalleryInclude>;

/**
 * ****************************************************************************
 * Front-end Types
 * ****************************************************************************
 */

export type ComputedMedia = {
  // Used for videos
  embedUrl?: string;
  thumbnailUrl?: string;

  // Used for images
  url?: string;
};

export type Media = Prisma.mediaGetPayload<undefined> & ComputedMedia;

/**
 * ****************************************************************************
 * Requests and Responses
 * ****************************************************************************
 */
// These types are subject to change.
type MediaToGalleryData = {
  galleryUuid: string;
  galleryPosition?: number;
};

export type ImageUpload = {
  mediaType: typeof MediaType.IMAGE;
  // Assumed to include file extension
  name: string;
  uuid: string;
  caption?: string;
};

export type VideoUpload = {
  mediaType: typeof MediaType.VIDEO;
  // Link to a vimeo video
  mediaUrl: string;
  name: string;
};

// These types are subject to change
export type BaseMediaUpload = XOR<ImageUpload, VideoUpload>;

// These types are subject to change
export type MediaUploadRequest = BaseMediaUpload & MediaToGalleryData;

// We explicitly remove the ability to change an image's name, as
// the extension in the name is tied to DigitalOcean's key
export type ImageEdit = {
  mediaType: typeof MediaType.IMAGE;
  caption?: string;
};

export type VideoEdit = {
  mediaType: typeof MediaType.VIDEO;
  // Link to a vimeo video
  mediaUrl?: string;
  name?: string;
};

export type MediaEditRequest = XOR<ImageEdit, VideoEdit> &
  Partial<MediaToGalleryData>;

// This is pretty much locked
export type CreateGalleryRequest = {
  media: BaseMediaUpload[];
  companyUuid?: string;
  projectUuid?: string;
};
