import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';

import { deserializeDates, withQueryParams } from '@/lib/apiUtils';
import { ChangelogUpdateRequest } from '@/types/api/phaseAndStages';
import { Project } from '@/types/api/projects';
import { HttpError } from '@/types/utilityTypes';

import { api } from './axios';

export const project = async (projectUuid: string) => {
  if (!projectUuid) {
    return;
  }

  try {
    const response = await fetch(`/api/projects/${projectUuid}`);
    const data = await response.json();
    if (!response.ok) {
      Sentry.addBreadcrumb({
        level: 'error',
        data: {
          error: data,
        },
      });
      throw new Error(response.statusText);
    }

    return deserializeDates(data) as Project;
  } catch (error) {
    Sentry.addBreadcrumb({
      level: 'error',
      data: {
        error: error,
      },
    });
    throw new Error(
      `Failed to fetch project with error: ${(error as Error).message}`
    );
  }
};

export const projects = async (excludeAirTableData: boolean) => {
  try {
    const response = await fetch(
      withQueryParams('/api/projects', {
        excludeAirTableData: excludeAirTableData ? 'true' : 'false',
      })
    );
    const data = await response.json();
    if (!response.ok) {
      Sentry.addBreadcrumb({
        level: 'error',
        data: {
          error: data,
        },
      });
      throw new Error(response.statusText);
    }

    return deserializeDates(data) as Project[];
  } catch (error) {
    Sentry.addBreadcrumb({
      level: 'error',
      data: {
        error,
      },
    });
    throw new Error(
      `Failed to fetch projects with error: ${(error as Error).message}`
    );
  }
};

export const updateChangelog = async (
  projectUuid: string,
  data: ChangelogUpdateRequest
) => {
  try {
    const response = await api.post(
      `/api/projects/${projectUuid}/changelog/update`,
      data
    );

    return response;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    Sentry.addBreadcrumb({
      level: 'error',
      data: {
        error: error,
      },
    });
    throw new Error(axiosError.response?.data.message);
  }
};
