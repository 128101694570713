import { IncomingHttpHeaders } from 'http';

import { isDateTime } from './stringUtils';

export function getClientIP(headers: IncomingHttpHeaders): string | undefined {
  let clientIP: string | undefined;

  const xForwardedFor = headers['x-forwarded-for'];

  if (xForwardedFor) {
    if (Array.isArray(xForwardedFor)) {
      // If it's an array, take the first IP address from the list
      clientIP = xForwardedFor[0]?.trim();
    } else if (typeof xForwardedFor === 'string') {
      // If it's a string, split it and take the first IP address
      clientIP = xForwardedFor.split(',')[0]?.trim();
    }
  }

  return clientIP;
}

export const withQueryParams = (
  url: string,
  queryParams: Record<string, string | undefined>
): string => {
  const params = new URLSearchParams();

  for (const [key, val] of Object.entries(queryParams)) {
    if (val) {
      params.append(key, val);
    }
  }
  const queryString = params.toString();

  return queryString ? `${url}?${queryString}` : url;
};

/**
 * A wrapper on top of Array.prototype.filter that allows an async filter function
 * to be passed in. Example usage:
 *
 *   const projectRosterCompanies = await asyncFilter(companies, (c) =>
 *     isCompanyInProjectRoster(c, project.id, userRole)
 *   );
 */
export const asyncFilter = async <T>(
  array: T[],
  filterFunc: (t: T) => Promise<boolean>
) => {
  const results = await Promise.all(array.map(filterFunc));

  return array.filter((_, index) => results[index]);
};

/**
 * Recursively converts date string values in the input data to Dates
 */
export const deserializeDates = <T>(data: T): T => {
  if (Array.isArray(data)) {
    return data.map((item) => deserializeDates(item)) as T;
  } else if (typeof data === 'object' && data !== null) {
    const deserialized: { [key: string]: unknown } = {};
    for (const key in data) {
      if (typeof data[key] === 'string' && isDateTime(data[key])) {
        deserialized[key] = new Date(data[key]);
      } else {
        deserialized[key] = deserializeDates(data[key]);
      }
    }

    return deserialized as T;
  }

  return data;
};
