import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import { company as getApiCompany } from '@/lib/api/client/companies';
import { Company } from '@/types/api/company';

import { createGetFetcher } from '../api/client/fetchers';
import { EMPTY_COMPANY } from '../constants/companies';
import { Role } from '../constants/role';
import { getRole, validateIsProfessional } from '../roleUtils';
import { useUserRoles } from './useUserRoles';

type GetCompanyParams = {
  roles: string[];
  setCompany: (company?: Company) => void;
  setError: (error: Error) => void;
  setLoading: (state: boolean) => void;
};

const getCompany = ({
  setCompany,
  setError,
  setLoading,
  roles,
}: GetCompanyParams) => {
  if (validateIsProfessional(roles)) {
    setLoading(true);
    getApiCompany()
      .then((response) => {
        setCompany(response);
      })
      .catch((e: Error) => setError(e))
      .finally(() => setLoading(false));
  } else {
    setCompany(EMPTY_COMPANY);
    setLoading(false);
  }
};

const get = createGetFetcher('company');

export const ROLES_ALLOWED_TO_GET_COMPANY = new Set([
  Role.GC,
  Role.ARCHITECT,
  Role.SUPPLIER,
  Role.CONSULTANT,
]);

/**
 * Used by professionals to get their company information.
 * @returns Company
 */
const useCompany = () => {
  const roles = useUserRoles();
  const role = getRole(roles);

  const shouldFetch = ROLES_ALLOWED_TO_GET_COMPANY.has(role);

  const { data, error, isLoading } = useSWR<Company, Error>(
    shouldFetch && '/api/user/company',
    get
  );

  return {
    company: data,
    loading: isLoading,
    error,
  };
};

/**
 * This hook can be used to get a pro's company information on a
 * regular interval.
 *
 * @param interval Time in milliseconds to query the getCompany endpoint
 * @param role The role of the logged-in user
 * @param gcCompanyId The pro's company id
 * @returns
 */
export const usePolledCompany = (interval: number) => {
  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const { status } = useSession({ required: true });
  const roles = useUserRoles();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // IntervalRef keeps track of the timer object returned by setInterval which
  // queries getCompany every <interval> milliseconds. If we want to stop
  // the timer, we create & pass stopPolling to the caller to give them a way
  // to clear the timer ref and stop calling getCompany.
  const stopPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    getCompany({
      setCompany,
      setError,
      setLoading,
      roles,
    });
    intervalRef.current = setInterval(() => {
      getCompany({
        setCompany,
        setError,
        setLoading,
        roles,
      });
    }, interval);

    return stopPolling;
  }, [roles, interval, stopPolling, status]);

  return {
    company,
    loading,
    error,
    stopPolling,
  };
};

export default useCompany;
