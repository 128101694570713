import { Avatar } from '@mantine/core';
import { IconContext } from 'react-icons';

export type HeaderIconProps = { icon?: React.JSX.Element; iconColor?: string };

export const HeaderIcon = ({ icon, iconColor = 'green' }: HeaderIconProps) => {
  if (!icon) {
    return <></>;
  }

  return (
    <Avatar size={68} radius={100} color={iconColor}>
      <IconContext.Provider value={{ size: '26px' }}>
        {icon}
      </IconContext.Provider>
    </Avatar>
  );
};
