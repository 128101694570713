import {
  Box,
  Col,
  createStyles,
  Flex,
  Grid,
  Image,
  ScrollArea,
} from '@mantine/core';
import { useContext } from 'react';

import {
  LayoutContext,
  LayoutType,
} from '@/components/shared/contexts/LayoutContext';

import { DisplaySize, SizeContext } from '../shared/contexts/SizeContext';
import CottageLogo from '../shared/svg/CottageLogo';

const useStyles = createStyles((theme) => ({
  logoOnImage: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    zIndex: 2,
  },
  col: {
    padding: '0px 0px 0px 0px',
  },
  rightCol: {
    padding: theme.spacing.xxl,
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      padding: theme.spacing.xl,
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: theme.spacing.xl,
      paddingBottom: '40px',
    },
  },
  gridFullHeight: {
    margin: 0, // removes Grid's default margin
  },
}));

type TwoColImageLayoutProps = {
  children: React.ReactNode;
  imageAltText: string;
  leftImagePath: string;
  bgColor?: string;
};

export const TwoColImageLayout = ({
  bgColor,
  children,
  leftImagePath,
  imageAltText,
}: TwoColImageLayoutProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;
  const { classes, theme } = useStyles();

  return (
    <Grid className={classes.gridFullHeight} grow align="center">
      <Col
        order={isMobile ? 2 : 1}
        sm={6}
        className={classes.col}
        data-testid="grid-col"
      >
        {!isMobile && (
          <Box w={100} className={classes.logoOnImage}>
            <CottageLogo color="white" />
          </Box>
        )}
        <Image src={leftImagePath} alt={imageAltText} height="100vh" />
      </Col>
      <Col
        order={isMobile ? 1 : 2}
        sm={6}
        className={classes.col}
        data-testid="grid-col"
      >
        <SizeContext.Provider
          value={{
            size: isMobile ? DisplaySize.COMPACT : DisplaySize.DEFAULT,
          }}
        >
          {isMobile && (
            <Box className={classes.rightCol} bg={bgColor}>
              <Box w={100} mb={48}>
                <CottageLogo color={theme.colors.blue[9]} />
              </Box>
              {children}
            </Box>
          )}
          {!isMobile && (
            <Flex
              h="100vh"
              bg={bgColor}
              justify="center"
              align="center"
              className={classes.rightCol}
            >
              {/* The max width needs to be set on the scroll area and not the
                  children or else it won't take up the max width of the page,
                  e.g. on the sign in page. */}
              <ScrollArea.Autosize
                mah="100%"
                w="100%"
                maw={454}
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              >
                {children}
              </ScrollArea.Autosize>
            </Flex>
          )}
        </SizeContext.Provider>
      </Col>
    </Grid>
  );
};
